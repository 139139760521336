<div class="wrapper">

  <div class="main-panel">
    <app-page-header></app-page-header>
    <div class="sidebar" *ngIf="showSideBar">
      <app-page-sidebar></app-page-sidebar>
    </div>
    <main class="main-content">
      <router-outlet></router-outlet>
      <app-page-footer></app-page-footer>
    </main>
    <!-- <app-page-right-sidebar></app-page-right-sidebar> -->
  </div>
</div>

<!-- <app-cookie-notification></app-cookie-notification> -->
<app-loader *ngIf="spinnerShow"></app-loader>
<app-alert-modal class="d-none" #alertModal></app-alert-modal>
