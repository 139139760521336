import { ThemeService } from '@core/services/theme.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertModalComponent } from '../shared/alert-modal/alert-modal.component';
import { MsspService } from '@core/tenable/service/mssp.service';
import { UserService } from '@core/services/user.service';
import { User } from '@core/models/user';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TenableChildAccount } from '@core/tenable/models/tenable-child-account';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  public showSideBar: boolean = true;
  public spinnerShow: boolean = true;

  @ViewChild('alertModal') alertModal!: AlertModalComponent;

  userModel!: User;
  companyModel!: Company;


  constructor(
    private msspService: MsspService,
    private themeService: ThemeService,
    private userSvc: UserService,
    private companySvc: CompanyService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.getUserCompany();
  }

  ngOnInit(): void {
    this.themeService.getShowSpinner().subscribe(showSpinner => {
      this.spinnerShow = showSpinner;
      this.cdRef.detectChanges(); // Manually trigger change detection
    });

    this.themeService.currentErrorMessage.subscribe(message => {

      if (message) {
        const modalRef = this.modalService.open(AlertModalComponent);
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showYesNoButtons = false;
      }
    });
  }

  async getUserCompany() {
    // this.themeService.showSpinner();
    await this.userSvc.getUserModel().subscribe(user => {
      if (user && user.uid) {
        // this.themeService.hideSpinner();
        this.userModel = user;

        this.companySvc.getDocument(this.userModel.companyId).then((company: Company) => {
          if (company && company.uid) {
            this.companyModel = company;
            if (this.companyModel && !this.companyModel.domain) {
              this.companyModel = this.companySvc.updateDomainFromOwnerEmail(this.companyModel, this.userModel);
            }
            if (!this.companyModel.accountUuid) {
              this.checkMsspAccountDetails();
            }
          }
        });


      }
    });
  }

  checkMsspAccountDetails() {
    try {
      // Check if account details are stored in session storage
      const storedAccountDetails = sessionStorage.getItem('accountDetails');
      if (storedAccountDetails) {
        const accountModel = JSON.parse(storedAccountDetails);
        if (accountModel.continaer_name) {
          // Proceed with using the stored account details
          const containerCompanyName = environment.production ? this.companyModel.uid : this.companyModel.uid + '-dev';
          if (accountModel.container_name != containerCompanyName) {
            console.error(' Admin Layout: An error occurred: Account details do not match company domain. Logging out..');
            this.themeService.triggerError('An error occurred: No Account Found. Contact Support. Logging out..');
            this.logoutUser();
          }
        } else {
          // If not stored, proceed to fetch and store account details
          this.msspService.listChildAccounts().subscribe((res: any) => {
            if (res && this.companyModel) {
              const containerCompanyName = environment.production ? this.companyModel.uid : this.companyModel.uid + '-dev';
              const accountModel = res.accounts.filter((accountDetails: TenableChildAccount) => accountDetails.container_name === containerCompanyName)[0];
              const validAccount = this.msspService.validateAccount(accountModel, this.companyModel);
              if (!validAccount) {
                this.logoutUser();
              }
            }
          });
        }
      }
    } catch (error: any) {
      console.error('An error occurred: ' + error.message);
    }
  }

  logoutUser() {
    this.authService.signOutUser();
    setTimeout(() => {
      if (this.alertModal) {
        this.alertModal.forceCloseModal();
      }
      return this.router.navigate(['/login']);
    }, 5000)
  }
}
